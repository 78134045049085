.filters{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    padding: .5em;

    .btn-primary {
        background-color: white;
        border-color: lightgrey;
        color:gray;
    }

    .search{
        margin-top: 1em;
        align-self: flex-end;
        text-align: center;
    }

    .dropdown-menu{
        max-height: 300px;
        overflow-y: auto;
    }

    .dropdown-menu.show{
        min-width: 20em;
    }
}