.book .language-available a {
  line-height: 1.5;
  padding: 0;
  text-align: left;
}
.book .language-available img {
  margin-right: 1em;
  margin-left: 2px;
  max-height: 15px;
  max-width: 15px;
}
.book .language-available span {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.book .language-available ul {
  list-style: none;
}

