@import "./global.scss";

.audio-card {
  border: 1px solid $main-color;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100px;
  margin: 5px;
  width: 250px;

  &__title {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 1em;
    overflow: auto;
    width: 90%;

    &_title {
      font-weight: bold;
    }
    &_author {
      font-size: 0.9em;
    }
    &_serie {
      font-style: italic;
      font-size: 0.8em;
    }
  }
}
