.subscriptions {
  input {
    margin-bottom: 1em;
  }
  &-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      align-items: center;
      margin-right: 1em;
      width: fit-content;
    }
  }

  input[type="checkbox"] {
    margin-right: 0.5em;
  }

  .title {
    font-weight: bold;
    margin-top: 5em;
  }

  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
}
