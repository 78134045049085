body {
  font-family: "Abel", sans-serif;
  line-height: 1.5;
  font-size: 0.917rem;
  background: radial-gradient(circle, white, #f8f9fc, rgba(16, 38, 54, 0.25));
  font-display: swap;
}

h1 {
  font-size: 2rem;
}

h1,
h2,
h3,
p,
li {
  color: #102636;
}

h2 {
  text-align: center;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(16, 38, 54, 0.75), rgba(0, 0, 0, 0));
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c7b5d5;
  box-shadow: 0 0 0 0.2rem rgba(227, 214, 237, 0.38);
}

.description {
  margin-left: 3em;
  margin-right: 3em;
  color: slategray;
}

@media (max-width: 768px) {
  .description {
    display: none;
  }
}
.text {
  color: white;
  margin-top: 50%;
  margin-left: 2em;
  margin-right: 2em;
}

.text-muted {
  color: white;
}

.nav-tabs {
  border-bottom: 1px solid #102636;
  background-color: #153852;
}

.nav-tabs .nav-link {
  color: rgba(255, 255, 255, 0.75) !important;
}

.nav-tabs .nav-link.active {
  color: #153852 !important;
  background-color: #f8f9fc;
}

.nav-tabs .nav-link.active:hover {
  color: #153852 !important;
}

.nav-tabs .nav-link:hover {
  color: white !important;
}

.nav-tabs span {
  margin-left: 0.5em;
}

.catalogue-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-auto-rows: 170px;
}

.btn-light {
  background-color: #ffffff;
}

.btn-primary {
  background-color: #1a4666;
  border-color: #153852;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #102636;
  border-color: #153852;
  color: #5da2d6 !important;
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.btn-outline-primary:not([disabled]):not(.disabled):active {
  background-color: #102636;
  border-color: #153852;
  box-shadow: 0 0 0 0.2rem #5da2d6;
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: #fff;
  color: #102636;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0.2rem #f8f9fa;
}

.btn-primary:focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem #5da2d6;
}

.btn-outline-primary {
  color: #153852;
  background-color: rgba(248, 249, 252, 0.8);
  background-image: none;
  border-color: #102636;
}

.btn-search > svg {
  text-align: center;
  height: 16px;
  color: "white";
}

.languages {
  background-color: #153852;
  text-align: right;
  font-size: small;
  padding-bottom: 2px;
}

.languages a {
  color: rgba(255, 255, 255, 0.75);
  padding-bottom: 0;
  padding-top: 0;
}

#sa_widget_browser {
  font-family: "Abel", sans-serif !important;
  font-display: swap;
}

.list-group-item.active {
  background-color: #153852;
  border-color: #153852;
}

.text-relevant {
  margin-bottom: 2em;
  margin-top: 2em;
}

.card-header {
  background-color: #153852;
  color: #fff;
}

.bold {
  font-weight: bold;
}

.loading {
  text-align: center;
}

.loading > svg {
  height: 30px;
  color: #153852;
}

.dropdown i {
  margin-right: 0.5em;
}
.dropdown .separatedIndex {
  border-bottom: #102636 1px dotted;
}

a.btn-info,
button.btn-info {
  background-color: #1375bd;
  border-color: #a5b1b9;
}

a.btn-info:hover,
button.btn-info:hover {
  background-color: #0e588f;
  border-color: #8898a3;
}

.sorter span {
  margin-right: 1em;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

