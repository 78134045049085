.cart td {
  vertical-align: middle;
}
.cart label {
  margin: 0px;
  padding: 0 1.25rem;
}
.cart input {
  margin-bottom: 0.5em;
}
.cart input[type=checkbox] {
  margin-right: 0.5em;
}
.cart .nav-tabs {
  background-color: #e9ecef;
  border-bottom: 1px solid #ced4da;
}
.cart .nav-tabs .nav-link {
  color: #7f8c96 !important;
}
.cart .td-image {
  width: 10%;
  min-width: 50px;
}
.cart .td-image div {
  height: 50px;
}
.cart .td-quantity {
  width: 25%;
}
.cart .td-quantity span {
  padding: 1em;
}
.cart .td-quantity button {
  opacity: 0.7;
}
.cart .td-quantity button:hover {
  opacity: 1;
}
.cart .td-quantity div {
  display: inline-flex;
}
.cart .form-group label {
  width: 100%;
}
.cart .form-group button span {
  font-size: large;
}
.cart .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
.cart .separator {
  padding-left: 1em;
  padding-right: 1em;
}

