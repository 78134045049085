.section-body {
  padding: 0px;
}
.section-body .accordion-body {
  padding: 0px 0px 10px 0px;
}
.section-body .accordion-body .container {
  padding: 0px;
}

