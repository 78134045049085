.avatar-circle,
.avatar-circle-sm {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.avatar-circle {
  width: 60px;
  height: 60px;
}

.avatar-circle-sm {
  width: 25px;
  height: 25px;
}

.initials {
  position: relative;
  color: #fff;
  font-family: "Abel", sans-serif;
  font-weight: bold;
  font-display: swap;
}

.avatar-circle .initials {
  top: 12px;
  font-size: 30px;
  line-height: 30px;
}

.avatar-circle-sm .initials {
  top: 0px;
  font-size: 12.5px;
  line-height: 12.5px;
}

