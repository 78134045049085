.audio-card {
  border: 1px solid #153852;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100px;
  margin: 5px;
  width: 250px;
}
.audio-card__title {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 1em;
  overflow: auto;
  width: 90%;
}
.audio-card__title_title {
  font-weight: bold;
}
.audio-card__title_author {
  font-size: 0.9em;
}
.audio-card__title_serie {
  font-style: italic;
  font-size: 0.8em;
}

