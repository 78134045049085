.author-container {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  margin: 20px;
  position: relative;
  height: 180px;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.author-container:hover {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(21, 56, 82, 0.5);
  -moz-box-shadow: 5px 5px 5px 0px rgba(21, 56, 82, 0.5);
  box-shadow: 5px 5px 5px 0px rgba(21, 56, 82, 0.5);
  background-color: white;
  opacity: 1;
}

.author-container img {
  width: 100px;
  box-sizing: border-box;
  border-left: none;
}

.author-container .author-description {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  text-align: center;
}
.author-container .author-description p {
  margin: 0;
  padding: 0;
}

.author-container .image {
  height: 123px;
}

