@import "./global.scss";

.book-title-container,
.book-title-container-tiny {
  border: 1px solid #00000000;
  border-radius: 3px;
  margin-bottom: 10px;
  position: relative;
  background-color: #00000000;
  box-sizing: border-box;
}

.book-title-container {
  width: 300px;
  height: 180px;
  padding: 10px;
}

.book-title-container-tiny {
  width: 100%;
  height: 75px;
}

.book-title-container:hover,
.book-title-container-tiny:hover {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(21, 56, 82, 0.5);
  -moz-box-shadow: 5px 5px 5px 0px rgba(21, 56, 82, 0.5);
  box-shadow: 5px 5px 5px 0px rgba(21, 56, 82, 0.5);
  background-color: white;
  opacity: 1;
}

.book-title-container img,
.book-title-container-tiny img {
  height: 100%;
  object-fit: scale-down;
  box-sizing: border-box;
  border-left: none;
}

.book-title-container img {
  width: 100px;
  max-width: 100px;
  max-height: 160px;
}
.book-title-container-tiny img {
  width: 50px;
  max-width: 50px;
  max-height: 75px;
}

.book-title-container .title-description,
.book-title-container-tiny .title-description {
  font-weight: bold;
  height: 38%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.book-title-container-tiny .title-description {
  height: 20px;
  width: 180px;
}

.book-title-container-tiny .title-description p {
  margin-bottom: 0px;
}
.book-title-container .button-container,
.book-title-container-tiny .button-container {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 180px;
  text-align: center;
}
.book-title-container-tiny .locales {
  position: absolute;
  top: 2px;
  right: 75px;
  text-align: right;
  width: 200px;
}
.book-title-container .button-container .btn-light,
.book-title-container-tiny .button-container .btn-light {
  background-color: #fff;
  border-color: #dfdfdf;
  color: rgb(48, 48, 48);
}
.book-title-container .button-container .btn-light:hover,
.book-title-container-tiny .button-container .btn-light:hover {
  background-color: rgb(225, 237, 255);
  color: rgb(76, 102, 141);
}

.book-title-container .authors,
.book-title-container-tiny .authors {
  overflow: hidden;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.book-title-container .authors {
  width: 180px;
}
.book-title-container-tiny .authors {
  width: 60%;
  min-width: 200px;
  height: 20px;
}

.book-title-container-tiny .title-description,
.book-title-container-tiny .locales {
  display: inline-block;
}
.book-title-container .locales img,
.book-title-container-tiny .locales img {
  margin-left: 2px;
  max-height: 15px;
  max-width: 15px;
}
