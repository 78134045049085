@import 'styles/_global.scss';

.down-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-downloads__item{
  height: 250px;
  max-width: 50%;
  position: relative;
  text-align: center;

  &-button {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 10%;

    button {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
