.subscriptions input {
  margin-bottom: 1em;
}
.subscriptions-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.subscriptions-items__item {
  align-items: center;
  margin-right: 1em;
  width: fit-content;
}
.subscriptions input[type=checkbox] {
  margin-right: 0.5em;
}
.subscriptions .title {
  font-weight: bold;
  margin-top: 5em;
}
.subscriptions .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

