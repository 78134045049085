.descimage {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: rgba(16, 38, 54, 0.7);
  overflow: hidden;
  height: 0;
  transition: 0.5s ease;
  margin-left: auto;
  margin-right: auto;
}

.descimage:hover .overlay {
  bottom: 0;
  height: 100%;
}

