.cart {
  td {
    vertical-align: middle;
  }

  label {
    margin: 0px;
    padding: 0 1.25rem;
  }

  input {
    margin-bottom: 0.5em;
  }

  input[type="checkbox"] {
    margin-right: 0.5em;
  }
  .nav-tabs {
    background-color: #e9ecef;
    border-bottom: 1px solid #ced4da;
    .nav-link {
      color: #7f8c96 !important;
    }
  }
  .td-image {
    width: 10%;
    min-width: 50px;

    div {
      height: 50px;
    }
  }
  .td-quantity {
    width: 25%;

    span {
      padding: 1em;
    }

    button {
      opacity: 0.7;
    }

    button:hover {
      opacity: 1;
    }

    div {
      display: inline-flex;
    }
  }
  .form-group {
    label {
      width: 100%;
    }

    button span {
      font-size: large;
    }
  }

  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }

  .separator {
    padding-left: 1em;
    padding-right: 1em;
  }
}
