.fixed-header {
  position: sticky;
  top: 0px;
  background-color: #153852;
  z-index: 10;
}
.fixed-header .options {
  color: white;
  padding-left: 0.5rem;
}
.fixed-header .options a {
  color: white;
}
.fixed-header .options .middle {
  text-align: center;
}
.fixed-header .options .language-check {
  display: flex;
  justify-content: center;
}
.fixed-header .options .language-check .checkbox {
  width: 2em;
  height: 100%;
  text-align: center;
}
.fixed-header .options .language-check .checkbox input {
  vertical-align: middle;
}

.main-header {
  position: relative;
  background-color: #153852;
}
.main-header .btn-search {
  color: #f8f9fc;
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 2.5em;
}
.main-header .btn-search:hover {
  border: 1px solid rgb(255, 255, 255);
}
.main-header .btn-search > span {
  color: #fff;
}

header {
  padding: 0px !important;
}

.navbar-brand {
  padding: 0 15px;
  height: 80px;
  line-height: 80px;
}

.navbar-toggle {
  margin-top: 23px;
  padding: 9px 10px !important;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 27px;
    padding-bottom: 27px;
    line-height: 27.5px;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.5em;
    padding-left: 1em;
  }
}
.search-input {
  margin: 0;
  display: inline-block;
}

.slogan {
  font-family: "Quintessential", cursive;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-display: swap;
}

.webcast {
  display: flex;
  justify-content: center;
}

.language-selector {
  text-align: center;
}
.language-selector img {
  width: 1em;
}
.language-selector a {
  padding: 5px;
}

