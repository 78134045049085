.book{
    .language-available{
        a{
            line-height: 1.5;
            padding: 0;
            text-align: left;
        }
        img{
            margin-right: 1em;
            margin-left: 2px;
            max-height: 15px;
            max-width: 15px;
        }
        span{
            word-wrap: break-word;
            white-space: pre-wrap;
        }
        ul{
            list-style: none;
        }
    }
}