@import "./global.scss";

.book-container-searchable {
  &__title {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    h3 {
      margin-right: 1em;
    }

    input[type="text"] {
      flex-grow: 1;
    }
  }
}

.sitsm {
  h3 {
    font-size: 1.5rem;
  }
}

.loader {
  height: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 8px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
