.down-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-downloads__item {
  height: 250px;
  max-width: 50%;
  position: relative;
  text-align: center;
}
.top-downloads__item-button {
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 10%;
}
.top-downloads__item-button button {
  background-color: rgba(255, 255, 255, 0.7);
}

