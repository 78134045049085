.filters {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding: 0.5em;
}
.filters .btn-primary {
  background-color: white;
  border-color: lightgrey;
  color: gray;
}
.filters .search {
  margin-top: 1em;
  align-self: flex-end;
  text-align: center;
}
.filters .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
.filters .dropdown-menu.show {
  min-width: 20em;
}

